import AutomateApplication from "../../../../../images/photo/solutions page/Digital Business Transformation/Automate Application.png";
import "./DigitalBusinessSection1.css";
const DigitalBusinessSection1 = () => {
  return (
    <div className="DigitalBusinessSection1">
      <div className="DigitalBusinessSection1-text">
        <div className="DigitalBusinessSection1-text-1">
          Automate Application Workflow:
        </div>
        <div className="DigitalBusinessSection1-text-2">
          WOREX Technology experts assist organizations in simplifying
          application and data workflow orchestration, whether on-premises or as
          a service. Our solutions provide:
        </div>
        <div className="DigitalBusinessSection1-text-3">
          <ul>
            <li>
              Advanced{" "}
              <a href="https://www.bmc.com/it-solutions/control-m-integrations.html#&sortCriteria=recommended&category=mp">
                {" "}
                workflow orchestration capabilities and connectivity to any
                application, any data source, and all your critical systems of
                record
              </a>
              , from mainframe to cloud.
            </li>
            <li>
              Proactive SLA Management: Enhance service delivery with
              intelligent predictive analytics that keep your operations within
              the agreed service level.
            </li>
            <li>
              Robust support for audits: Ensure full compliance and governance,
              safeguarding your operations.
            </li>
            <li>
              Proven stability: Rely on our proven stability, as thousands of
              companies have scaled their operations from tens to millions of
              jobs with zero downtime.
            </li>
          </ul>
        </div>
      </div>
      <div className="DigitalBusinessSection1-image">
        <img src={AutomateApplication} alt="Automate Application" />
      </div>
    </div>
  );
};

export default DigitalBusinessSection1;
