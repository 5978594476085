import { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";

import { NavRoutes } from "./../../Static/NavRoutes";
import SearchBarModal from "./../../common/SearchBarModal/SearchBarModal";
import SolutionsDropdown from "./SolutionsDropdown/SolutionsDropdown";
import OverlayNav from "./OverlayNav/OverlayNav";
import coloredLogo from "../../images/Worex-logo/color.png";
import yellowLogo from "../../images/Worex-logo/yellow.svg";

import styled from "styled-components";
import { Search } from "@styled-icons/bootstrap/Search";
import { MenuOutline } from "@styled-icons/evaicons-outline/MenuOutline";
import "./Nav.css";

const SearchIcon = styled(Search)``;
const MenuIcon = styled(MenuOutline)``;

function NavBar() {
  const [modalShow, setModalShow] = useState(false);
  const [overlayState, setOverlayState] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const pathname = useLocation().pathname;
  const navOptions = NavRoutes();
  // const pathNamesArr = pathname.split("/");

  const handelCloseModal = () => setModalShow(false);

  const handelCloseOverlay = () => {
    setOverlayState(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navContainer ${isScrolled ? "scrolled" : ""}`}>
      <div className="divContainer">
        <div className="logoContainer">
          <Link to="/">
            <img
              src={isScrolled ? coloredLogo : yellowLogo}
              className={"navLogo"}
              alt="Worex"
            />
          </Link>
        </div>
        <div className="optionsContainer">
          <div>
            <ul>
              {navOptions.map((NavRoute, index) => (
                <li key={index} className={`${NavRoute.name}-dropdown`}>
                  <NavLink
                    to={NavRoute.route}
                    className={`routeStyle ${isScrolled ? "scrolled" : ""} ${
                      NavRoute.route === pathname ? "activeRoute" : ""
                    }`}
                  >
                    {NavRoute.name}
                  </NavLink>
                  <div className="dropDownContainer">
                    {NavRoute.dropdown ? (
                      <SolutionsDropdown dropDownContent={NavRoute.dropdown} />
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="SearchIconStyle">
            <button
              className="SearchButton"
              onClick={() => {
                setModalShow(true);
              }}
            >
              <SearchIcon
                className={`SearchIcon ${isScrolled ? "scrolled" : ""}`}
              />
            </button>
          </div>
        </div>
        <div className="menu">
          <MenuIcon
            className={`MenueBtn ${isScrolled ? "scrolled" : ""}`}
            onClick={() => {
              setOverlayState(true);
            }}
          />
        </div>
      </div>
      <SearchBarModal
        showState={modalShow}
        handelCloseModal={handelCloseModal}
      />

      <OverlayNav
        overlayState={overlayState}
        handelCloseOverlay={handelCloseOverlay}
        NavRoutes={navOptions}
      />
    </nav>
  );
}

export default NavBar;
