import styled from "styled-components";
import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";

import "./ITManagementSection2.css";
const CheckCircleIcon = styled(CheckCircle)`
  color: #7f68b4;
  width: 24px;
`;
const ITManagementSection2 = () => {
  return (
    <div className="ITManagementSection2">
      <div className="ITManagementSection2-top">
        Infrastructure & Cloud Management
      </div>
      <div className="ITManagementSection2-bottom">
        <div className="ITManagementSection2-bottom-square">
          <div className="ITManagementSection2-bottom-card">
            <CheckCircleIcon />
            <p>
              <span>
                Fault & Performance Management of your whole infrastructure
                including:
              </span>{" "}
              Storage, Servers, Applications, Databases.
            </p>
          </div>
          <div className="ITManagementSection2-bottom-card">
            <CheckCircleIcon />
            <p>
              <span>Backup & Disaster Recovery Management</span>
            </p>
          </div>
        </div>
        <div className="ITManagementSection2-bottom-square">
          <div className="ITManagementSection2-bottom-card">
            <CheckCircleIcon />
            <p>
              <span>
                Cloud Management & Chargeback for whole cloud services in your
                private, public & hybrid clouds
              </span>
            </p>
          </div>
          <div className="ITManagementSection2-bottom-card">
            <CheckCircleIcon />
            <p>
              <span>Multi-Vendor Support</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITManagementSection2;
