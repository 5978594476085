import ADIB from "../../../images/photo/home/Feedback/ADIB.svg";
import stc from "../../../images/photo/home/Feedback/stc.svg";
import CIB from "../../../images/photo/home/Feedback/CIB.svg";
import Soyven from "../../../images/photo/home/Feedback/Soyven.png";
import Vodafone from "../../../images/photo/home/TrustedCompanies/Vodafone.png";

export default function CustomerOpinionStaticData() {
  return [
    {
      image: ADIB,
      companyName: "Abu Dhabi Islamic Bank (ADIB)",
      companyFeedback:
        "Thanks for your follow up and for excellent selection by Assign this project to Worex team, they did great job with supportive attitude that deserve all thanks and appreciation.",
    },
    {
      image: stc,
      companyName: "STCSC",
      companyFeedback:
        "I would like to show my appreciation and gratefulness to have Worex Technology help us in a journey of one year of ITSM transformation for our Call Center and IT Helpdesk. This journey outcomes were reflected on our operational KPIs and had a great impact on business performance. The system has shown high ROI, regardless of the challenges that have appeared during the project delivery [COVID-19 lockdown, new DC, Infrastructure changes] , which Worex helped to adapt seamlessly to the changes and solved the connectivity challenges we have faced during the delivery stage. We’re glad to do business with the Worex Technology Team, as they’ve shown professional and dedicated customer satisfaction for any project-related or technical obstacle we had to overcome together",
    },
    {
      image: CIB,
      companyName: "CIB Bank",
      companyFeedback:
        "Worex team are Professional IT Management solution consultants, always responsive to any inquiry and provide guidance and flexibility through their qualified & professional team during their projects & engagements. They help the bank through innovative IT solutions that increase productivity, and save time and money. They showed great creativity and they are keen on providing great ROI to CIB in each project, one of the top best choices to us",
    },
    {
      image: Soyven,
      companyName: "Soyven",
      companyFeedback:
        "I would like to show my appreciation and gratitude to have Worex Technology help in the IT Service Management project implementation for Soyven IT department. The system has shown great feedback, regardless of the challenges that have appeared during the project delivery, which were seamlessly solved by the cooperation with Worex team. We’re glad to do business with Worex Technology Team, as they’ve shown professional and dedicated customer satisfaction for any project-related or technical obstacle we had to overcome together.",
    },
    {
      image: Vodafone,
      companyName: "Vodafone",
      companyFeedback:
        "I need to thank all involved stakeholders from WOREX involved in Remedy Upgrade Project, starting from design, planning, implementation, testing and finally going live on Sep 10th, 2022. It was a long 9 months journey ended with a real success story resulted from the hard work and dedication showed from both WOREX and Vodafone. Wishing you all the best in future projects.",
    },
  ];
}
