import "./Section3.css";

const ContactUsSection3 = () => {
  return (
    <div className="ContactUsSection3">
      <div className="ContactUsSection3-title">Our Location</div>
      <div className="ContactUsSection3-mapContainer">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.1262046360243!2d31.232238674353273!3d29.975802821821855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584709cb0ac307%3A0x4d7a52c5aa7a6166!2sWorex%20Technology!5e0!3m2!1sen!2seg!4v1700569933280!5m2!1sen!2seg"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="GoogleMap"
          className="ContactUsSection3-map"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUsSection3;
