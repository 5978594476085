import SolutionsHeader from "./../SolutionsHeader/SolutionsHeader";
import ItSolutions from "./Sections/IT_Solutions/IT_Solutions";
import WorkingSteps from "./Sections/WorkingSteps/WorkingSteps";

const Solutions = () => {
  const path = window.location.pathname;

  return (
    <>
      <SolutionsHeader pageName={"All Solutions"} path={path} />
      <ItSolutions />
      <WorkingSteps />
    </>
  );
};

export default Solutions;
