import "./ClientsHeader.css";

const ClientsHeader = ({ pageName, path }) => {
  const routes = path?.split("/");
  routes[0] = "Home";
  return (
    <div className="ClientsHeader">
      <div className="ClientsHeaderText">
        <h1>{pageName}</h1>
        <div className="ClientsHeaderPath">
          {routes.map((route, index) => (
            <div key={index}>
              {index !== routes.length - 1 ? (
                <>{route} / </>
              ) : (
                <div className="ClientsHeaderPath-last">{pageName}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsHeader;
