import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import { SolutionsStaticData } from "../../Solutions/All Solutions/Sections/IT_Solutions/SolutionsStaticData";
import OurSolutionHoverCard from "./HoverCard/OurSolutionHoverCard";

import "./ourSolutions.css";
const ArrowRightCircleIcon = styled(ArrowRightCircle)``;

const OurSolution = () => {
  const staticData = SolutionsStaticData();

  return (
    <div className="OurSolutionContainer">
      <div className="OurSolutionBox">
        <div className="tob-Section">
          <h1>Our Solutions</h1>
          <div className="tob-Section-1">
            <div className="tob-Section-1-1">
              <p>
              Discover the perfect match for your unique business needs with our diverse range of
              technologies. From groundbreaking innovations to reliable, time-tested solutions, we offer the
              ideal technology to propel your business forward.
              </p>
            </div>
            <div className="tob-Section-1-2">
              <Link to="/Solutions">
                <div className="tob-Section-btn">
                  View All Solutions{" "}
                  <ArrowRightCircleIcon className="OurSolutionArrowRightCircleIcon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="OurSolutionBox-bottom-Section">
          <div className="OurSolutionBox-bottom-Section-cards">
            {staticData.map((el, index) => (
              <OurSolutionHoverCard
                key={index}
                image={el.image}
                title={el.title}
                icon={el.icon}
                description={el.description}
                route={el.route}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSolution;
