import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { sendEmail } from "./../../../../service/emailService";

import HighQuality from "../../../../images/photo/about us/High-quality IT support.jpg";
import PhoneInput from "react-phone-number-input";
import SuccessModal from "./../../../../common/SuccessModal/SuccessModal";

import "react-phone-number-input/style.css";
import "./Section1.css";
import { isValidPhoneNumber } from "react-phone-number-input";

const ContactUsSection1 = () => {
  const [modalShow, setModalShow] = useState(false);
  const regex =
    /^[a-zA-Z0-9!#$%&*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-z]{2,4})+$/;

  const schema = yup
    .object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .required("Email is required")
        .email("Must be a valid email")
        .matches(regex, "Must be a valid email")
        .max(255),
      phone: yup.string().min(11).required("Phone is required"),
      message: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      BigData: false,
      DigitalBusiness: false,
      ITManagement: false,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (!isValidPhoneNumber(data.phone)) {
      setError("phone", { type: "custom", message: "Invalid phone number" });
      return;
    }
    // Email js
    try {
      await sendEmail(data);
      setModalShow(true);
      reset();
    } catch (error) {
      console.log("FAILED...", error.text);
      alert("Email Service error");
    }
  };

  return (
    <>
      <div className="ContactUsSection1">
        <div className="ContactUsSection1-left">
          <div className="ContactUsSection1-left-1">Get in touch</div>
          <div className="ContactUsSection1-left-2">
            We’d love to hear from you. Please fill out this form.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ContactUsSection1-left-3">
              <div className="ContactUsSection1-left-3-top">
                <div className="ContactUsSection1-left-3-top-FName">
                  <label className="ContactUsSection1-left-3-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    {...register("firstName")}
                    className="ContactUsSection1-left-3-input-1"
                    placeholder="Your First Name"
                    maxLength={20}
                    name="firstName"
                  />
                  <div className="error-span">{errors.firstName?.message}</div>
                </div>
                <div className="ContactUsSection1-left-3-top-LName">
                  <label className="ContactUsSection1-left-3-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    {...register("lastName")}
                    className="ContactUsSection1-left-3-input-1"
                    maxLength={20}
                    placeholder="Your Last Name"
                  />
                  <div className="error-span">{errors.lastName?.message}</div>
                </div>
              </div>
              <div className="ContactUsSection1-left-3-mid">
                <div className="ContactUsSection1-left-3-email">
                  <label className="ContactUsSection1-left-3-label">
                    Email
                  </label>
                  <input
                    type="email"
                    {...register("email")}
                    className="ContactUsSection1-left-3-input-2"
                    placeholder="Email@gmail.com"
                    maxLength={255}
                  />
                  <div className="error-span">{errors.email?.message}</div>
                </div>
                <div className="ContactUsSection1-left-3-phone">
                  <label className="ContactUsSection1-left-3-label">
                    Phone
                  </label>
                  <PhoneInput
                    defaultCountry="EG"
                    international
                    countryCallingCodeEditable={false}
                    value={watch().phone}
                    {...register("phone")}
                    onChange={(value) => setValue("phone", value)}
                    limitMaxLength={true}
                    placeholder="Enter phone number"
                    className="ContactUsSection1-left-3-PhoneInput"
                  />
                  <div className="error-span">{errors.phone?.message}</div>
                </div>
              </div>
              <div className="ContactUsSection1-left-3-message">
                <label className="ContactUsSection1-left-3-label">
                  Message (optional)
                </label>
                <textarea
                  rows={4}
                  className="ContactUsSection1-left-3-textarea"
                  placeholder="placeholder"
                  {...register("message")}
                ></textarea>
              </div>
            </div>
            <div className="ContactUsSection1-left-4">
              <div>
                <input
                  type="checkbox"
                  {...register("DigitalBusiness")}
                  onClick={() =>
                    setValue("DigitalBusiness", !watch().DigitalBusiness)
                  }
                />
                <div>Digital Business Automation</div>
              </div>
              <div>
                <input
                  type="checkbox"
                  {...register("BigData")}
                  onClick={() => setValue("BigData", !watch().BigData)}
                />
                <div>Data and AI</div>
              </div>
              <div>
                <input
                  type="checkbox"
                  {...register("ITManagement")}
                  onClick={() =>
                    setValue("ITManagement", !watch().ITManagement)
                  }
                />
                <div>IT Management</div>
              </div>
            </div>
            <div className="ContactUsSection1-left-5">
              <input type="submit" className="ContactUsSection1-left-5-btn" />
            </div>
          </form>
        </div>
        <div className="ContactUsSection1-right">
          <img src={HighQuality} alt="High Quality" />
        </div>
      </div>
      <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default ContactUsSection1;
