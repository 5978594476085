import MyCarousel from "../../../common/MyCarousel/MyCarousel";
import Alteryx from "../../../images/icons/Our Technologies/Alteryx.png";
import opentext from "../../../images/icons/Our Technologies/opentext.png";
import cloudera from "../../../images/icons/Our Technologies/cloudera.png";
import IBM from "../../../images/icons/Our Technologies/IBM.png";
import MicroFocus from "../../../images/icons/Our Technologies/Micro_Focus.png";
import bmc from "../../../images/icons/Our Technologies/bmc.png";
import dell from "../../../images/icons/Our Technologies/dell.png";
import hewlett from "../../../images/icons/Our Technologies/hewlett.png";
import informatica from "../../../images/icons/Our Technologies/informatica.png";
import ivanti from "../../../images/icons/Our Technologies/ivanti.png";
import microsoft from "../../../images/icons/Our Technologies/microsoft.png";
import solarwind from "../../../images/icons/Our Technologies/solarwind.png";
import zendesk from "../../../images/icons/Our Technologies/zendesk.png";
import splunk from "../../../images/icons/Our Technologies/splunk.webp";
import ServiceNow from "../../../images/icons/Our Technologies/ServiceNow.png";
import Cisco from "../../../images/icons/Our Technologies/Cisco.png";
// import { Link } from "react-router-dom";
// import styled from "styled-components";
// import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";

import "./OurTechnology.css";
// const ArrowRightCircleIcon = styled(ArrowRightCircle)``;

const OurTechnology = () => {
  const TechArray = [
    Alteryx,
    opentext,
    cloudera,
    IBM,
    MicroFocus,
    bmc,
    dell,
    hewlett,
    informatica,
    ivanti,
    microsoft,
    solarwind,
    zendesk,
    splunk,
    ServiceNow,
    Cisco,
  ];

  return (
    <div className="OurTechnologyContainer">
      <div className="OurTechnologyTopSection">
        <div className="TopSectionTitle-1"> Our Technologies</div>
        <div className="TopSectionTitle-2">
          Worex Technology has certified consultants and expertise accross the
          following technology vendors
        </div>
      </div>
      <div className="OurTechnologybottomSection">
        <MyCarousel
          elements={TechArray}
          elementClassName="OurTechnologyCarousalImg"
        />
        {/* <div className="OurTechnology-btn-container">
          <div className="OurTechnology-btn">
            <Link to={"/Technologies"} className="OurTechnologies-btn">
              View All
              <ArrowRightCircleIcon className="OurTechnologyArrowRightCircleIcon" />
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurTechnology;
