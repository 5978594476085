import DBA_img from "./images/DBA_img.svg";
import DBA_icon from "../../../../../images/icons/solution card icons/digital business transformation 1.svg";
import BDS_img from "./images/BDS_img.svg";
import BDS_icon from "../../../../../images/icons/solution card icons/Big data.svg";
import ITM_img from "./images/ITM_img.svg";
import ITM_icon from "../../../../../images/icons/solution card icons/IT Managment 1.svg";
export function SolutionsStaticData() {
  return [
    {
      image: BDS_img,
      title: "Data and AI Services",
      icon: BDS_icon,
      description:
        "Unlock the power of your data with our cutting-edge Data Warehouse technology. Gain valuable insights, optimize analytics...",
      route: "/Solutions/DataAndAIServices",
    },
    {
      image: DBA_img,
      title: "Digital Business Automation",
      icon: DBA_icon,
      description:
        "WOREX Technology experts helps organizations using Workflow automation solutions to simplifies application and data workflow...",
      route: "/Solutions/DigitalBusinessAutomation",
    },
    {
      image: ITM_img,
      title: "IT Management",
      icon: ITM_icon,
      description:
        "Experience excellence in IT management with our seasoned experts. With years of industry experience under our belt, our dedicated consultants...",
      route: "/Solutions/ITManagement",
    },
  ];
}
