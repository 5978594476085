import CountUp from "react-countup";
const MyCountUp = ({ end, suffix }) => {
  return (
    <CountUp
      start={0}
      end={end}
      duration={5}
      delay={0}
      // separator=" "
      suffix={suffix}
      className="CounterNumber"
    />
  );
};

export default MyCountUp;
