import "./DataAndAIServicesSection4.css";

const DataAndAIServicesSection4 = () => {
  const articles = [
    {
      title: "Enterprise data management: Comprehensive Solutions for Success",
      description:
        "Efficiently Manage Your Enterprise Data: Elevate your data management with our comprehensive solutions. Gain control, ensure data integrity, enhance security, and unlock valuable insights. Drive informed decision-making and propel your business to success.",
    },
    {
      title: "Data Security",
      description:
        "Revolutionize your digital business with our intelligent Master Data Management (MDM) solution. Achieve a seamless 360-degree view of enterprise data, enabling valuable insights. Benefit from rapid deployment, all-in-one capabilities, prebuilt content, self-service access, and AI-powered automation to boost productivity.",
    },
    {
      title: "Data Governance & Compliance",
      description:
        "In today's digital transformation era, our advanced data governance solutions provide consistent, reliable, and accurate data. Shift from a risk-focused approach to an agile, data intelligence-driven strategy. Empower your decision-making with trusted data, driving your business towards success.",
    },
    {
      title: "Data Quality",
      description:
        "Enhance your analytics with our leading-edge solution for data quality. Ensure seamless cleansing and maintenance of top-notch data quality across all stakeholders, domains, and applications. Leverage automated processes for end-to-end data quality, leading to trustworthy insights and efficient operations.",
    },
    {
      title: "Data Integration",
      description:
        "Discover the potential of data integration to unlock hidden insights and make better business decisions. Connect structured and unstructured data from diverse sources, including social media, apps, CRM, ERP, and beyond. Transform data into actionable intelligence to achieve your business goals.",
    },
  ];

  return (
    <div className="DataAndAIServicesSection4">
      <div className="DataAndAIServicesSection4-header">Data Managment</div>
      <div className="DataAndAIServicesSection4-definitions">
        {articles.map((el, index) => (
          <div className="DataAndAIServicesSection4-card" key={index}>
            <div className="DataAndAIServicesSection4-title">{el.title}</div>
            <div className="DataAndAIServicesSection4-description">
              {el.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataAndAIServicesSection4;
