import HeaderSlider from "./HeaderSection/HeaderSlider";
import WhyChooseUs from "./whyChooseUs/whyChooseUs";
import OurSolution from "./ourSolutions/ourSolutions";
import Trusted from "./Trusted/Trusted";
import OurTechnology from "./OurTechnology/OurTechnology";
import CustomersOpinion from "./CustomersOpinion/CustomersOpinion";

const Home = () => {
  return (
    <>
      <HeaderSlider />
      <WhyChooseUs />
      <OurSolution />
      <Trusted />
      <OurTechnology />
      <CustomersOpinion />
    </>
  );
};

export default Home;
