import "./ContactUsHeader.css";

const ContactUsHeader = ({ pageName, path }) => {
  const routes = path?.split("/");
  routes[0] = "Home";
  return (
    <div className="ContactUsHeader">
      <div className="ContactUsHeaderText">
        <h1>{pageName}</h1>
        <div className="ContactUsHeaderPath">
          {routes.map((route, index) => (
            <div key={index}>
              {index !== routes.length - 1 ? (
                <div>{route} / </div>
              ) : (
                <div className="ContactUsHeaderPath-last">{pageName}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUsHeader;
