import { Link } from "react-router-dom";
import { AnimatedOnScroll } from "react-animated-css-onscroll";

import "./OurSolutionHoverCard.css";

const OurSolutionHoverCard = (props) => {
  const { image, title, icon, description, route } = props;

  // const observer = new IntersectionObserver((entries) => {
  //   // Loop over the entries
  //   entries.forEach((entry) => {
  //     // If the element is visible
  //     if (entry.isIntersecting) {
  //       // Add the animation class
  //       entry.target.classList.add("add-pop-in-animation");
  //     }
  //   });
  // });

  // observer.observe(document.querySelector(".OurSolutionHoverCardContainer"));

  return (
    <AnimatedOnScroll animationIn="zoomIn" duration={0.1}>
      <div className="OurSolutionHoverCardContainer">
        <img src={image} alt="Avatar" className="HoverCardImage" />
        <div className="OurSolutionHoverCardTitle">
          <div>{title}</div>
        </div>
        <div className="OurSolutionHoverCardMiddle">
          <div className="OurSolutionHoverCardMiddleText">
            <img src={icon} alt={title} />
            <h3>{title}</h3>
            <p>{description}</p>
            <div>
              <Link to={route}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    </AnimatedOnScroll>
  );
};

export default OurSolutionHoverCard;
