import "./OurTechnologiesHeader.css";

const OurTechnologiesHeader = ({ pageName, path }) => {
  const routes = path?.split("/");
  routes[0] = "Home";
  return (
    <div className="OurTechnologiesHeader">
      <div className="OurTechnologiesHeaderText">
        <h1>{pageName}</h1>
        <div className="OurTechnologiesHeaderPath">
          {routes.map((route, index) =>
            index !== routes.length - 1 ? (
              <>{route} / </>
            ) : (
              <div>{pageName}</div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OurTechnologiesHeader;
