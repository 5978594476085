import OurMission from "./OurMission.svg";
import OurVision from "./OurVision.svg";
import "./Section1.css";
import Section1Card from "./Section1Card/Section1Card";

const Section1 = () => {
  const staticData = [
    {
      image: OurMission,
      title: "Our Mission",
      description:
        "Empower business and boost corporate ROI by providing top-notch consultancy and professional services for the optimal IT solutions.",
    },
    {
      image: OurVision,
      title: "Our Vision",
      description:
        "To transform IT from a cost center to a profit center by providing tailored turnkey IT solutions optimized for each business sector.",
    },
  ];
  return (
    <div className="Section1">
      <div className="Section1-top">
        <div className="Section1-top-1">
          Empowering enterprise transformation at <span>speed and scale</span>
        </div>
        <div className="Section1-top-2">
          WOREX Technology is a premier regional IT services firm delivering
          world-class solutions to leaders across various sectors. With over
          <span> 20 years</span> proven experience, our expertly chosen team
          builds innovative solutions tailored for Telecommunications,
          Financial, Manufacturing, Education, and Government sectors. At WOREX,
          we leverage a vast huge of talented resources, extensive experience
          with international vendors, and various business domains. we have the
          know-how to craft solutions that not only empower businesses but also
          significantly increase ROI.
        </div>
        <div className="Section1-top-3">
          WOREX maintains regional offices in Cairo and Riyadh, positioning us
          strategically to serve our clients effectively across the region.
        </div>
      </div>
      <div className="Section1-bottom">
        <div className="Section1-bottom-1">
          {staticData.map((el, index) => (
            <Section1Card
              key={index}
              image={el.image}
              title={el.title}
              description={el.description}
            />
          ))}
        </div>
        <div className="Section1-bottom-2">
          <div className="Section1-bottom-2-title">Our Value Proposition</div>
          <div className="Section1-bottom-2-definitions">
            <div className="Section1-bottom-2-definitions-partition">
              <div className="Section1-bottom-2-definitions-card">
                <span>Experience:</span> our rich background and innovative
                journey distinguish us. For more than two decades, we left our
                imprint in enhancing the performance of large-scale businesss by
                developing up-to-the-moment solutions. We consistently pushed
                the envelope to show what’s possible in IT.
              </div>
              <div className="Section1-bottom-2-definitions-card">
                <span>Exclusive Solutions:</span> Worex’s methods, structures,
                processes and systems are uniquely our own. We perpetually
                monitor the evolution of markets and new technologies to develop
                solutions that reflect the realities our clients experience.
              </div>
            </div>
            <div className="Section1-bottom-2-definitions-partition">
              <div className="Section1-bottom-2-definitions-card">
                <span>Our Customers are our Priority:</span> We not only improve
                our clients’ operational processes and capacities but also
                commit to achieving tangible results. This commitment to
                excellence defines our work ethic. We step into your shoes,
                understand your needs, and strategically meet your goals. We
                take no other alternative.
              </div>
              <div className="Section1-bottom-2-definitions-card">
                <span>Return on Investment (ROI):</span> Our projects not only
                result in economic gains through cost savings and enhanced
                performance but also optimize processes and resource efficiency.
                This leads to tangible, sustainable returns on investment for
                our clients.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
