import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import HeaderSlideContent from "./SlideContent/HeaderSlideContent";
import StaticHeaderSlideContent from "./StaticHeaderSlideContent.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./CustomSwiperStyle.css";

import "./HeaderSlider.css";
import { useState } from "react";

function HeaderSlider() {
  const [swiper, setSwiper] = useState(null);

  const handleSlideChange = () => {
    // Check if swiper instance exists
    if (swiper) {
      // Trigger animation on current slide
      const activeSlideIndex = swiper.activeIndex;
      swiper.slides.forEach((slide, index) => {
        if (index === activeSlideIndex) {
          const animationElements = slide.querySelectorAll(
            ".slideInDown-Animation, .fadeInLeft-Animation, .fadeIn-Animation"
          );
          animationElements.forEach((element) => {
            element.style.animation = "none";
            void element.offsetWidth; // Trigger reflow to restart animation
            element.style.animation = null;
          });
        }
      });
    }
  };
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      loop={true}
      modules={[Autoplay, Pagination]}
      onSwiper={setSwiper}
      onSlideChange={handleSlideChange}
      className="HeaderSwiper"
    >
      {StaticHeaderSlideContent.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className={`HeaderSlider ${slide.image}`}>
            <HeaderSlideContent slide={slide} slideNumber={index} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default HeaderSlider;
