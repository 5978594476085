import ITManagementImage from "../../../../../images/photo/solutions page/IT Management/IT Management.jpg";

import "./ITManagementSection1.css";

const ITManagementSection1 = () => {
  return (
    <div className="ITManagementSection1">
      <div className="ITManagementSection1-left">
        <div className="ITManagementSection1-left-1">IT Management:</div>
        <div className="ITManagementSection1-left-2">
        Experience excellence in IT management with our seasoned experts; each backed by years of industry experience.
        Our dedicated consultants deliver unrivaled IT solutions, meticulously tailored to meet your specific needs. 
        Trust in our expertise to optimize your IT infrastructure and propel your business forward with confidence.
        </div>
        <div className="ITManagementSection1-left-3">
          <ul>
            <li> Infrastructure & Cloud Management</li>
            <li>Network Management</li>
            <li>Application Performance Management</li>
            <li>IT Service & Asset Management</li>
            <li>Capacity Management</li>
            <li>Software Development Life Cycle Management</li>
          </ul>
        </div>
      </div>
      <div className="ITManagementSection1-right">
        <img src={ITManagementImage} alt="IT Management" />
      </div>
    </div>
  );
};

export default ITManagementSection1;
