import emailjs from "@emailjs/browser";

export function sendEmail({
  firstName,
  lastName,
  email,
  phone,
  message,
  BigData,
  DigitalBusiness,
  ITManagement,
}) {
  let combinedMessage = message ? `${message}\n` : "";
  if (BigData || DigitalBusiness || ITManagement) {
    combinedMessage += `\nIntersted Fields:`;
    if (DigitalBusiness)
      combinedMessage = combinedMessage += `\n- Digital Business Automation`;
    if (BigData)
      combinedMessage = combinedMessage += `\n- BIG Data & Analytics`;
    if (ITManagement) combinedMessage = combinedMessage += `\n- IT Management`;
  }

  return emailjs.send(
    process.env.REACT_APP_SERVICE_ID,
    process.env.REACT_APP_TEMPLATE_ID,
    {
      to_name: "Worex",
      from_name: `${firstName} ${lastName}`,
      user_email: email,
      message: combinedMessage,
      phone: phone,
      reply_to: "",
    },
    process.env.REACT_APP_publicKey
  );
}
