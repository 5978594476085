import AboutUsHeader from "./Sections/AboutUsHeader/AboutUsHeader";
import Section1 from "./Sections/Section1/Section1";
import Section2 from "./Sections/Section2/Section2";
import Section3 from "./Sections/Section3/Section3";

import "./AboutUs.css";

const AboutUs = () => {
  const path = window.location.pathname;
  return (
    <div className="AboutUs">
      <AboutUsHeader pageName={"About Worex"} path={path} />
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
};

export default AboutUs;
