import Alteryx from "../../../../images/icons/Our Technologies/Alteryx.png";
import opentext from "../../../../images/icons/Our Technologies/opentext.png";
import cloudera from "../../../../images/icons/Our Technologies/cloudera.png";
import IBM from "../../../../images/icons/Our Technologies/IBM.png";
import bmc from "../../../../images/icons/Our Technologies/bmc.png";
import dell from "../../../../images/icons/Our Technologies/dell.png";
import hewlett from "../../../../images/icons/Our Technologies/hewlett.png";
import informatica from "../../../../images/icons/Our Technologies/informatica.png";
import ivanti from "../../../../images/icons/Our Technologies/ivanti.png";
import microsoft from "../../../../images/icons/Our Technologies/microsoft.png";
import solarwind from "../../../../images/icons/Our Technologies/solarwind.png";
import zendesk from "../../../../images/icons/Our Technologies/zendesk.png";
import splunk from "../../../../images/icons/Our Technologies/splunk.webp";
import ServiceNow from "../../../../images/icons/Our Technologies/ServiceNow.png";
import Cisco from "../../../../images/icons/Our Technologies/Cisco.png";

import "./OurTechnologiesSection1.css";

const OurTechnologiesSection1 = () => {
  const TechArray = [
    Alteryx,
    opentext,
    cloudera,
    IBM,
    bmc,
    dell,
    hewlett,
    informatica,
    ivanti,
    microsoft,
    solarwind,
    zendesk,
    splunk,
    ServiceNow,
    Cisco,
  ];

  return (
    <div className="OurTechnologiesSection1">
      {TechArray.map((logo, index) => (
        <div className="OurTechnologiesSection1-logo fadeInBottom" key={index}>
          <img src={logo} alt={logo} />
        </div>
      ))}
    </div>
  );
};

export default OurTechnologiesSection1;
