import SolutionsHeader from "../SolutionsHeader/SolutionsHeader";
import DataAndAIServicesSection1 from "./Sections/Section1/DataAndAIServicesSection1";
import DataAndAIServicesSection2 from "./Sections/Section2/DataAndAIServicesSection2";
import DataAndAIServicesSection3 from "./Sections/Section3/DataAndAIServicesSection3";
import DataAndAIServicesSection4 from "./Sections/Section4/DataAndAIServicesSection4";

import "./DataAndAIServices.css";

const DataAndAIServices = () => {
  return (
    <div className="DataAndAIServices">
      <SolutionsHeader
        pageName={"Data and AI Services"}
        path={window.location.pathname}
      />
      <DataAndAIServicesSection1 />
      <DataAndAIServicesSection2 />
      <DataAndAIServicesSection4 />
      <DataAndAIServicesSection3 />
    </div>
  );
};

export default DataAndAIServices;
