import Section2Card from "./Section2-card/Section2Card";
import "./Section2.css";

const ContactUsSection2 = () => {
  const staticData = [
    {
      title: "Egypt",
      address: "25-Corniche EL Nile-Maadi-Tower 3-Floor 23",
      phone: ["+202_25250555", "0225252509"],
      fax: "+202_25250555",
      mobile: "+201203220008",
      postalCode: "11433",
      email: "info@worex.com",
    },
    {
      title: "USA",
      address: "1000 N West Street, Suite 1200, Wilmington, DE 19801",
      mobile: "+13022039182",
      email: "info@worex.com",
    },
    {
      title: "Saudi Arabia",
      address: "12333 King Fahd Olaya, Riyadh, Saudi Arabia",
      mobile: "+966559027821",
      email: "info@worex.com",
      postalCode: "12271",
    },
  ];

  return (
    <div className="ContactUsSection2">
      <div className="ContactUsSection2-title">Worldwide Offices</div>
      <div className="ContactUsSection2-cards-Container">
        {staticData.map((el, index) => (
          <Section2Card
            key={index}
            title={el.title}
            address={el.address}
            phone={el.phone}
            fax={el.fax}
            mobile={el.mobile}
            postalCode={el.postalCode}
            email={el.email}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactUsSection2;
