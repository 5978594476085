import ITSupport from "../../../../images/photo/about us/High-quality IT support.jpg";
import CheckboxCircle from "../../../../images/photo/home/solution section/checkbox.svg";
import styled from "styled-components";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import { Link } from "react-router-dom";

import "./Section2.css";
const ArrowRightCircleIcon = styled(ArrowRightCircle)`
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const Section2 = () => {
  const services = [
    "Managed IT Services",
    "Data and AI Services",
    "IT Support & Helpdesk",
    "Digital Business Automation",
  ];
  return (
    <div className="Section2">
      <div className="Section2-left">
        <img src={ITSupport} alt="IT Support" />
      </div>
      <div className="Section2-right">
        <div className="Section2-right-1">High-quality IT support</div>
        <div className="Section2-right-2">
          Driving business excellence through <span> Digital Automation.</span>
        </div>
        <div className="Section2-right-3">
          WOREX Technology is a regional IT services firm providing world-class
          solutions to regional leading organizations with an emphasis on
          Advanced Analytics, digital business automation and IT Performance
          Management. We have proven track records of building state-of-the-art
          solutions that empower the business and increase ROI through digital
          transformation.
        </div>
        <div className="Section2-right-4">
          {services.map((service, index) => (
            <li key={index}>
              <img src={CheckboxCircle} alt="" className="CheckboxCircle" />
              <div>{service}</div>
            </li>
          ))}
        </div>
        <div className="Section2-right-5">
          <Link to="/ContactUs">
            <div className="Section2-right-5-btn">
              Contact With Us <ArrowRightCircleIcon />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Section2;
