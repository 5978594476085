import { NavLink, useLocation } from "react-router-dom";

import "./SolutionsDropdown.css";

const SolutionsDropdown = ({ dropDownContent }) => {
  const pathname = useLocation().pathname;
  return (
    <div className="SolutionsDropdown">
      {dropDownContent?.map((el, index) => (
        <NavLink
          to={el.route}
          key={index}
          className={`dropdownRoute ${
            el.route === pathname ? "activeDropdownRoute" : ""
          }`}
        >
          <div className="dropDownCard">
            <div>
              <img src={el.image} className="dropDownCardImg" alt={el.name} />
            </div>
            <div className="dropDownCard-right">
              <div className="dropDownCardTitle">{el.name}</div>
              <div className="dropDownCardDescription">{el.description}</div>
            </div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default SolutionsDropdown;
