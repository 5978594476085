import "./ContactUs.css";
import ContactUsHeader from "./Sections/ContactUsHeader/ContactUsHeader";
import ContactUsSection1 from "./Sections/Section1/Section1";
import ContactUsSection2 from "./Sections/Section2/Section2";
import ContactUsSection3 from "./Sections/Section3/Section3";

const ContactUs = () => {
  const path = window.location.pathname;
  return (
    <div className="ContactUs">
      <ContactUsHeader pageName={"Contact Us"} path={path} />
      <ContactUsSection1 />
      <ContactUsSection2 />
      <ContactUsSection3 />
    </div>
  );
};

export default ContactUs;
