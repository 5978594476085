import Modal from "react-bootstrap/Modal";
import thanks from "./thanks.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";

import "./SuccessModal.css";
const ArrowRightCircleIcon = styled(ArrowRightCircle)`
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;
const SuccessModal = (props) => {
  return (
    <Modal
      {...props}
      // size="lg"
      // centered
      dialogClassName="SuccessModal" // same level as modal-dialog
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body>
        <div className="SuccessModalBody">
          <div className="SuccessModalBody-1">
            <img src={thanks} alt={"thanks"} />
          </div>
          <div className="SuccessModalBody-2">Thank you</div>
          <div className="SuccessModalBody-3">
            Your submission has been received, we will be in touch and contact
            you soon!
          </div>
          <div className="SuccessModalBody-4">
            <Link to={"/"} onClick={props.onHide}>
              <div className="SuccessModalBody-4-btn">
                Back to Home <ArrowRightCircleIcon />
              </div>
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
