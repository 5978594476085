import "./Clients.css";
import ClientsHeader from "./Sections/ClientsHeader/ClientsHeader";
import ClientsSection1 from "./Sections/Section1/ClientsSection1";

const Clients = () => {
  const path = window.location.pathname;

  return (
    <div className="Clients">
      <ClientsHeader pageName={"Clients"} path={path} />
      <ClientsSection1 />
    </div>
  );
};

export default Clients;
