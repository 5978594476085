import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import MyCountUp from "./MyCountUp";
import "./whyChooseUs.css";

const WhyChooseUs = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className="whyChooseUsContainer">
      <div className="sectoion-1">
        <h1>Why WOREX</h1>
        <p>
          Choose expertise and innovation with WOREX; two birds one stone. Our
          handpicked experts bring over 20 years of proven excellence in
          building up-to-the-minute solutions. Rely on our team and place your
          confidence in us to propel your business to new heights with our track
          record of innovation and experience. Elevate your technology landscape
          with WOREX today
        </p>
      </div>
      <div className="sectoion-2">
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          // onExit={() => setCounterOn(false)}
        >
          {counterOn && (
            <div className="grid-container">
              <div className="grid-item">
                <MyCountUp end={82} suffix={"k+"} />
                <p>Consultancy hours</p>
              </div>
              <div className="grid-item">
                <MyCountUp end={130} suffix={"+"} />
                <p>Projects</p>
              </div>
              <div className="grid-item">
                <MyCountUp end={80} suffix={"+"} />
                <p>Satisfied customer</p>
              </div>
              <div className="grid-item">
                <MyCountUp end={15} suffix={"+"} />
                <p>Technologies</p>
              </div>
              <div className="grid-item">
                <MyCountUp end={18} suffix={"+"} />
                <p>Business use cases</p>
              </div>
              <div className="grid-item">
                <MyCountUp end={12} suffix={"+"} />
                <p>Work with countries</p>
              </div>
            </div>
          )}
        </ScrollTrigger>
      </div>
    </div>
  );
};

export default WhyChooseUs;
