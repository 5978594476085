import "./Section1Card.css";

const Section1Card = ({ image, title, description }) => {
  return (
    <div className="Section1Card">
      <div className="Section1Card-left">
        <img src={image} alt={title} />
      </div>
      <div className="Section1Card-right">
        <div className="Section1Card-right-title">{title}</div>
        <div className="Section1Card-right-description">{description}</div>
      </div>
    </div>
  );
};

export default Section1Card;
