import { Link } from "react-router-dom";
import yellowWorexLogo from "../../images/Worex-logo/yellow.svg";
import styled from "styled-components";
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { LinkedinSquare } from "@styled-icons/boxicons-logos/LinkedinSquare";
import { Location } from "@styled-icons/evil/Location";
import { Telephone } from "@styled-icons/bootstrap/Telephone";
import Landline from "../../images/photo/footer/hangedPhone.svg";
import { Printer } from "@styled-icons/feather/Printer";
import { ArrowIosUpward } from "@styled-icons/evaicons-solid/ArrowIosUpward";

import "./Footer.css";

const FacebookCircleIcon = styled(FacebookCircle)``;
const InstagramIcon = styled(Instagram)``;
const LinkedinSquareIcon = styled(LinkedinSquare)``;

const LocationIcon = styled(Location)`
  width: 32px;
`;
const TelephoneIcon = styled(Telephone)``;
const PrinterIcon = styled(Printer)``;
const ArrowIosUpwardIcon = styled(ArrowIosUpward)`
  width: 24px;
  height: 24px;
`;
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footer-section-1">
        <div className="footer-section-1-text">
          <div className="footer-section-1-text-1">
            Looking for the Best IT Business Solutions?
          </div>
          <div className="footer-section-1-text-2">
            As an app web crawler expert, we will pave the way for you to
            organize.
          </div>
        </div>
        <div className="footer-section-1-btn">
          <Link to={"./ContactUs"}>
            <div>Get a quote</div>
          </Link>
        </div>
      </div>
      <div className="footer-section-2">
        <div className="footer-section-2-left">
          <img src={yellowWorexLogo} alt="Worex" />
          <div className="footer-section-2-left-text">
            Worex helps businesses of all sizes to improve their IT
            infrastructure and operations. We offer a wide range of solutions
            and services.
          </div>
          <div className="footer-section-2-left-input">
            <input type="text" placeholder="Enter email..." />
            <button>Submit</button>
          </div>
        </div>
        <div className="footer-section-2-right">
          <div className="footer-grid-Container">
            <div className="footer-grid-item footer-solution">
              <div>Solutions</div>
              <Link to={"/Solutions/DigitalBusinessAutomation"}>
                <li>Digital Business Automation</li>
              </Link>
              <Link to={"/Solutions/DataAndAIServices"}>
                <li>Data and AI Services</li>
              </Link>
              <Link to={"/Solutions/ITManagement"}>
                <li>IT Managment</li>
              </Link>
            </div>
            <div className="footer-grid-item footer-links">
              <div>Links</div>
              <Link to={"/"}>
                <li>Home</li>
              </Link>
              <Link to={"/Solutions"}>
                <li>Solutions</li>
              </Link>
              <Link to={"/Technologies"}>
                <li>Technologies</li>
              </Link>
              <Link to={"/Clients"}>
                <li>Clients</li>
              </Link>
              <Link to={"/AboutUs"}>
                <li>About Us</li>
              </Link>
              <Link to={"/ContactUs"}>
                <li>Contact Us</li>
              </Link>
            </div>
            <div className="footer-grid-item footer-Address">
              <div>Address</div>
              <li>
                <LocationIcon className="footer-address-Icons" />
                <div>
                  25-Corniche EL Nile-Tower 3-Floor 23, Maadi, Cairo, Egypt.
                </div>
              </li>
              <li>
                <TelephoneIcon className="footer-address-Icons" />
                <div>+201093334886</div>
              </li>
              <li>
                <img src={Landline} alt="" className="footer-address-Icons" />
                <div>+202_25250444</div>
              </li>
              <li>
                <PrinterIcon className="footer-address-Icons" />
                <div>+202_25250555</div>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section-3">
        <div className="footer-section-3-left">
          <div className="footer-section-3-left-1">
            © Copyright 2024. WOREX Technology - All Rights Reserved
          </div>
          <div className="footer-section-3-left-2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/"
            >
              <FacebookCircleIcon className="footer-Icons" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/worextechnology/?igshid=MzRlODBiNWFlZA%3D%3D"
            >
              <InstagramIcon className="footer-Icons" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/worex-technology/mycompany/"
            >
              <LinkedinSquareIcon className="footer-Icons" />
            </a>
          </div>
        </div>
        <div className="footer-section-3-right">
          <button onClick={scrollToTop} className="footer-section-3-right-btn">
            <ArrowIosUpwardIcon />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
