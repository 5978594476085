import HoverCard from "./HoverCard/HoverCard";
import { SolutionsStaticData } from "./SolutionsStaticData";
import "./IT_Solutions.css";

const ItSolutions = () => {
  const staticData = SolutionsStaticData();

  return (
    <div className="IT_Solutions">
      <div className="IT_Solutions-text">
        <div className="IT_Solutions-text-1">
          Preparing for Your Success: {" "}
          <span>Premier IT Solutions</span>
        </div>
        <div className="IT_Solutions-text-2">
        Explore a world of possibilities with our unique
        collection of technologies, pedantically tailored to
        meet your specific business needs. Whether you're
        seeking cutting-edge innovations or reliable, time-
        tested solutions, we offer the perfect technology to
        ensure your success.
        </div>
      </div>
      <div className="IT_Solutions-bottom">
        {staticData.map((el, index) => (
          <HoverCard
            key={index}
            image={el.image}
            title={el.title}
            icon={el.icon}
            description={el.description}
            route={el.route}
          />
        ))}
      </div>
    </div>
  );
};

export default ItSolutions;
