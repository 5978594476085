import BigDataServices from "../../../../../images/photo/solutions page/big data/Big Data Services.png";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "./DataAndAIServicesSection3.css";

const ArrowRightCircleIcon = styled(ArrowRightCircle)`
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const DataAndAIServicesSection3 = () => {
  return (
    <div className="DataAndAIServicesSection3">
      <div className="DataAndAIServicesSection3-top">
        <div className="DataAndAIServicesSection3-top-left">
          <div className="DataAndAIServicesSection3-top-left-1">
            Big Data Services
          </div>
          <div className="DataAndAIServicesSection3-top-left-2">
            Big Data Consultancy:
          </div>
          <div className="DataAndAIServicesSection3-top-left-3">
            We are harnessing the power of Big Data to revolutionize your
            business with our expert consultancy services.
          </div>
          <div className="DataAndAIServicesSection3-top-left-4">
            <ul>
              <li> Corporate business objective</li>
              <li>
                Business requirements analysis and conceptual solution
                elaboration
              </li>
              <li>Architecture design and technology selection</li>
              <li>Implementation</li>
              <li>Maintenance and support</li>
            </ul>
          </div>
        </div>
        <div className="DataAndAIServicesSection3-top-right">
          <img src={BigDataServices} alt="Big Data Services" />
        </div>
      </div>
      <div className="DataAndAIServicesSection3-mid">
        <div className="DataAndAIServicesSection3-mid-2">
          Building Data Lake:
        </div>
        <div className="DataAndAIServicesSection3-mid-3">
          Leverage our global team of skilled consultants for personalized big
          data solutions. Enhance performance, modernize databases, and utilize
          advanced analytics tailored to your unique business needs. Benefit
          from our expertise in the complete Hadoop ecosystem, driving
          innovation from the start.
        </div>
        <div className="DataAndAIServicesSection3-mid-4">
          <ul>
            <li> Business case analysis and development</li>
            <li>Hadoop performance tuning</li>
            <li>Architecture and platform development</li>
            <li>Data warehouse migration</li>
            <li>Hadoop deployment, installation and setup</li>
          </ul>
          <ul>
            <li>Hadoop cluster upgrades</li>
            <li>Cluster capacity planning</li>
            <li>POC through production solution; plan, build, deploy</li>
            <li>Data modeling</li>
            <li> Security requirements analysis, design, and implementation</li>
          </ul>
        </div>
      </div>

      <div className="DataAndAIServicesSection3-bottom">
        <Link to={"/ContactUs"}>
          <div className="DataAndAIServicesSection3-bottom-btn">
            Talk to an expert <ArrowRightCircleIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DataAndAIServicesSection3;
