import { Link } from "react-router-dom";
import "./HoverCard.css";

const HoverCard = (props) => {
  const { image, title, icon, description, route } = props;

  return (
    <div className="HoverCardContainer">
      <img src={image} alt="Avatar" className="HoverCardImage" />
      <div className="HoverCardTitle">
        <div>{title}</div>
      </div>
      <div className="HoverCardMiddle">
        <div className="HoverCardMiddleText">
          <img src={icon} alt={title} />
          <h3>{title}</h3>
          <p>{description}</p>
          <div>
            <Link to={route}>Learn more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoverCard;
