import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Search } from "@styled-icons/bootstrap/Search";
import "./SearchBarModal.css";

const SearchIcon = styled(Search)`
  color: white;
  width: 22px;
  height: 22px;
  align-content: center;
`;

const SearchBarModal = (props) => {
  const { showState, handelCloseModal } = props;

  return (
    <>
      <Modal
        size="lg"
        show={showState}
        onHide={handelCloseModal}
        centered
        dialogClassName="SearchBarModal" // same level as modal-dialog
        aria-labelledby="example-custom-modal-styling-title"
        // aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body style={{ padding: "0px", border: "none" }}>
          <div className="searchBox">
            <div style={{ width: "100%" }}>
              <input
                type="search"
                maxlength="30"
                className="searchInput"
                placeholder="Search..."
              />
            </div>
            <div>
              <SearchIcon />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SearchBarModal;
