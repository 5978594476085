import img1 from "./SVGs/img1.svg";
import img2 from "./SVGs/img2.svg";
import img3 from "./SVGs/img3.svg";
import img4 from "./SVGs/img4.svg";
import img5 from "./SVGs/img5.svg";
import "./Section3.css";

const Section3 = () => {
  const services = [
    {
      image: img1,
      title: "Consultancy & Professional Services",
      definition:
        "A professional services consultant advises organizations on how to improve short- or long-term strategies, processes, and policies.",
    },
    {
      image: img2,
      title: "Managed Services",
      definition:
        "The managed services model is a way to offload general tasks to an expert in order to reduce costs, improve service quality, or free internal teams to do business-specific work",
    },
    {
      image: img3,
      title: "Training",
      definition:
        "We provide training in a variety of formats, including classroom instruction, online courses, and hands-on workshops.",
    },
    {
      image: img4,
      title: "Operations Support",
      definition:
        "Operations Support is essential for the smooth running of any business.(visits, outsourcing, remote support with SLA)",
    },
    {
      image: img5,
      title: "Quality Assurance",
      definition:
        "A process of ensuring products or services meet specific requirements and standards coveriing the whole life-cycle from design to implementation.",
    },
  ];

  return (
    <div className="Section3">
      <div className="Section3-title">How We Can Help You</div>
      <div className="Section3-subTitle">Our Services</div>
      <div className="Section3-bottom">
        <div>
          {services.map((service, index) => (
            <div className="Section3-bottom-card" key={index}>
              <img
                className="Section3-bottom-card-img"
                src={service.image}
                alt={service.title}
              />
              <div className="Section3-bottom-card-title">{service.title}</div>
              <div className="Section3-bottom-card-definition">
                {service.definition}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section3;
