import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";

import "./CustomersOpinion.css";
import CustomerOpinionSwiper from "./Swiper/CustomerOpinionSwiper";
const ArrowRightCircleIcon = styled(ArrowRightCircle)`
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;
const CustomersOpinion = () => {
  return (
    <div className="CustomersOpinionContainer">
      <div className="CustomersOpinionTobSection">
        <div className="CustomersOpinionTobSection-1">
         Customer Testimonials
        </div>
        <div className="CustomersOpinionTobSection-2">
          Discover What Our Clients Say About <div>Our Solutions</div>
        </div>
        <div className="CustomersOpinionTobSection-3">
          Read the following genuine comments from our clients. Don't just take
          our word for it—see what our satisfied customers have to say about their experiences.
        </div>
      </div>
      <div className="CustomersOpinionMedSection">
        <CustomerOpinionSwiper />
      </div>
      <div className="CustomersOpinionbottomSection">
        <Link to={"/Solutions"}>
          <div className="CustomersOpinionbottomSection-btn">
            Let's start now <ArrowRightCircleIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CustomersOpinion;
