import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Search } from "@styled-icons/bootstrap/Search";
import yellowLogo from "../../../images/Worex-logo/yellow.svg";
import "./OverlayNav.css";

const SearchIcon = styled(Search)`
  color: #6c757d;
  width: 24px;
  height: 24px;
  align-content: center;
`;

const OverlayNav = ({ overlayState, handelCloseOverlay, NavRoutes }) => {
  const pathname = useLocation().pathname;

  return (
    <div
      id="myNav"
      className="overlayNav"
      style={{
        height: overlayState ? "100%" : "0%",
      }}
    >
      <div className="closebtn" onClick={handelCloseOverlay}>
        &times;
      </div>
      <div className="overlayNav-content">
        <Link to="/">
          <img
            src={yellowLogo}
            width="162px"
            height="54.01px"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Link>
        <div className="overlayBottom">
          <div className="overlaySearchBar-container">
            <div className="overlaySearchBar">
              <input
                type="text"
                placeholder="Search..."
                className="overlaySearchBarInput"
              />
              <SearchIcon />
            </div>
          </div>
          <div className="overlayOptions">
            {NavRoutes.map((NavRoute, index) => (
              <div key={index}>
                <NavLink
                  to={NavRoute.route}
                  className={`overlayOption ${
                    NavRoute.route === pathname ? "activeOption" : ""
                  }`}
                  onClick={handelCloseOverlay}
                >
                  {NavRoute.name}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayNav;
