import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from "./components/NavBar/Nav";
import Footer from "./components/Footer/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <NavBar />
        <AppRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
