import styled from "styled-components";
import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";

import "./DataAndAIServicesSection2.css";
const CheckCircleIcon = styled(CheckCircle)`
  color: #7f68b4;
  width: 24px;
`;
const DataAndAIServicesSection2 = () => {
  return (
    <div className="DataAndAIServicesSection2">
      <div className="DataAndAIServicesSection2-1">AI and Data Science</div>
      <div className="DataAndAIServicesSection2-2">
       Elevate Your Business Potential with Advanced AI & Data Science Technology
       Transform your business with the unparalleled power of data-driven decision-
       making, predictive analytics, and machine learning. Our technology empowers
       unrivaled efficiency, innovation, and success in the digital era.
      </div>
      <div className="DataAndAIServicesSection2-3">
        <div className="DataAndAIServicesSection2-3-square">
          <div className="DataAndAIServicesSection2-3-card">
            <CheckCircleIcon />
            <p>
              <span>Approachable AutoML:</span> Scale data science throughout your organization with
              automated machine learning (AutoML) and advanced feature engineering.
              Empower both business domain experts and data scientists to rapidly accelerate
              insight generation.
            </p>
          </div>
          <div className="DataAndAIServicesSection2-3-card">
            <CheckCircleIcon />
            <p>
              <span>Deep Feature Synthesis:</span> Utilize our automated feature engineering method to
               discover high-quality features built on the relationships within your data. This
               method enables efficient model creation that is understandable, explainable,
               and actionable for strategic decision-making.
            </p>
          </div>
          <div className="DataAndAIServicesSection2-3-card">
            <CheckCircleIcon />
            <p>
              <span>Best-in-Class Algorithms:</span> Enhance the trust and transparency of your models
              with access to our Open-Source libraries. Our Alteryx Open-Source packages,
              available on GitHub, include Woodwork, Compose, Feature tools, and EvalML.
            </p>
          </div>
        </div>
        <div className="DataAndAIServicesSection2-3-square">
          <div className="DataAndAIServicesSection2-3-card">
            <CheckCircleIcon />
            <p>
              <span>Uncover Hidden Signals:</span> Leverage Machine Learning’s automated insight
              generation to quickly reveal hidden signals and key relationships in your data,
              enhancing your strategic initiatives.
            </p>
          </div>

          <div className="DataAndAIServicesSection2-3-card">
            <CheckCircleIcon />
            <p>
              <span>Approachable Machine Learning:</span> Build and refine machine learning models with
              ease using our in-product Education Mode. Engage and collaborate with the
              vibrant Alteryx community to enhance your analytical capabilities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAndAIServicesSection2;
