import dataAndAIServices from "../../../../../images/photo/solutions page/big data/dataAndAIServices.jpg";
import "./DataAndAIServicesSection1.css";

const DataAndAIServicesSection1 = () => {
  return (
    <div className="DataAndAIServicesSection1">
      <div className="DataAndAIServicesSection1-left">
        <div className="DataAndAIServicesSection1-left-1">Data Warehouse:</div>
        <div className="DataAndAIServicesSection1-left-2">
          Unlock the Power of Your Data with Advanced Data Warehouse Technology
          Empower your business decisions with unparalleled insights using our
          leading- edge Data Warehouse technology. Optimize your analytics and
          access valuable intelligence like never before.
        </div>
        <div className="DataAndAIServicesSection1-left-3">
          <ul>
            <li>
              <span>Enterprise-Class Data Analytics:</span> The WOREX team aids
              organizations in implementing an enterprise analytics service that
              significantly accelerates the time to insight, bridging the gap
              between data warehouses and big data systems.
            </li>
            <li>
              <span>Limitless Scaling:</span> Rapidly deliver insights from all
              your data, scaling effortlessly across data warehouses and big
              data analytics systems.
            </li>
            <li>
              <span>Powerful Machine Learning Insights:</span> Uncover deep
              insights from all your data, transformed through sophisticated
              machine learning models, to boost the intelligence of your
              applications.
            </li>
            <li>
              <span>Streamlined Development:</span> Experience a unified
              platform for developing end-to-end analytics solutions that
              drastically cuts down on project development time.
            </li>
            <li>
              <span>Unmatched Security Features:</span> Benefit from the most
              advanced security and privacy measures available, including
              column- and row-level security and dynamic data masking, ensuring
              your data's integrity and confidentiality.
            </li>
          </ul>
        </div>
      </div>
      <div className="DataAndAIServicesSection1-right">
        <div className="overlay"></div>
        <img src={dataAndAIServices} alt="Data Warehouse" />
      </div>
    </div>
  );
};

export default DataAndAIServicesSection1;
