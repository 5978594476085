import "./SolutionsHeader.css";

const SolutionsHeader = ({ pageName, path, endPathName }) => {
  const routes = path?.split("/");
  routes[0] = "Home";
  return (
    <div className="SolutionsHeader">
      <div className="SolutionsHeaderText">
        <h1>{pageName}</h1>
        <div className="SolutionsHeaderPath">
          {routes.map((route, index) => (
            <div key={index}>
              {index !== routes.length - 1 ? (
                <div>{route} / </div>
              ) : (
                <div className="SolutionsHeaderPath-last">
                  {endPathName || pageName}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolutionsHeader;
