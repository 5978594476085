import { Link } from "react-router-dom";
// import X from "./X-logo.ico";
import "./HeaderSlideContent.css";

const HeaderSlideContent = ({ slide, slideNumber }) => {
  const { title, subTitle, description, redirection, ContactUs } = slide;
  return (
    <div
      className={`HeaderSlideContentContainer ${
        slideNumber === 0 ? "welcomeSlide" : ""
      }`}
    >
      <div className="HeaderSlideContent-left">
        <div className="HeaderSlideTextBox">
          <div className="HeaderSlideTitle slideInDown-Animation">{title}</div>
          <div className="HeaderSlideSubTitle fadeInLeft-Animation">
            {subTitle}
          </div>
          <div className="HeaderSlideDescription fadeIn-Animation">
            {description}
          </div>
        </div>
        <div className="HeaderSlideBtnBox">
          {ContactUs && (
            <Link to="/ContactUs">
              <button className="HeaderSlideContact">Contact Us</button>
            </Link>
          )}
          {redirection && (
            <Link to={redirection}>
              <button className="HeaderSlideDiscover">Discover More</button>
            </Link>
          )}
        </div>
      </div>
      {/* <div className="HeaderSlideContent-right">
        <img src={X} alt="Worex" />
      </div> */}
    </div>
  );
};

export default HeaderSlideContent;
