import StepsGraph from "./StepsGraph/StepsGraph";
import "./WorkingSteps.css";

const WorkingSteps = () => {
  return (
    <div className="WorkingSteps">
      <div className="WorkingStepsText">
        <div className="WorkingStepsText-1">How we work</div>
        <div className="WorkingStepsText-2">
          We make your success story become true
        </div>
        <div className="WorkingStepsText-3">
          IT services can help businesses to succeed by improving their IT
          infrastructure, reducing their IT costs, increasing customer
          satisfaction, and enhancing their competitive advantage. Here’s how we
          help you succeed:
        </div>
      </div>
      <div className="WorkingStepsGraph">
        <StepsGraph />
      </div>
    </div>
  );
};

export default WorkingSteps;
