import bmc from "../../../../../images/photo/solutions page/Digital Business Transformation/bmc.png";
import "./DigitalBusinessSection3.css";
const DigitalBusinessSection3 = () => {
  return (
    <div className="DigitalBusinessSection3">
      <div className="DigitalBusinessSection3-text">
        <div className="DigitalBusinessSection3-text-1">Control-M from BMC</div>
        <div className="DigitalBusinessSection3-text-2">
        Elevate your operations with our advanced application and data workflow orchestration that has consistently won awards 
        for its performance and innovation. Experience the transformative benefits:
        </div>
      </div>
      <div className="DigitalBusinessSection3-bottom">
        <div className="DigitalBusinessSection3-list">
          <ul>
            <li>
              <span>Enhanced Service Levels:</span> Align both internal and external* file transfers with business process flows through a unified view,
              improving overall service delivery.
            </li>
            <li>
              <span>Instant Visibility:</span> Gain immediate insight into the status of all file transfers, 
              whether internal or external, across both cloud and on-premise infrastructures.
            </li>
            <li>
              <span>Proactive Business Support:</span> Quickly address file transfer-related issues before they affect your operations,
              thereby supporting your business lines more effectively.
            </li>
            <li>
              <span>Accelerated Application Delivery:</span> Support agile delivery practices to increase the speed of application deployment 
              and responsiveness to market changes.
            </li>
            <li>
              <span>Risk Reduction:</span> Minimize business service risks and cut down on remediation costs with an integrated view 
              that combines file transfers and related workloads.
            </li>
          </ul>
        </div>
        <div className="DigitalBusinessSection3-bottom-right">
          <img src={bmc} alt="bmc" />
        </div>
      </div>
    </div>
  );
};

export default DigitalBusinessSection3;
