import { Link } from "react-router-dom";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import styled from "styled-components";

import "./ITManagementSection3.css";

const ArrowRightCircleIcon = styled(ArrowRightCircle)`
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const ITManagementSection3 = () => {
  // const definitions = [
  //   {
  //     title: "Network Management:",
  //     points: [
  //       "Automatic discovery & proactive monitoring for the whole network infrastructure",
  //       "Performance & quality measurement",
  //       "Automation & compliance management",
  //       "Traffic Analysis & Bandwidth optimization",
  //     ],
  //   },
  //   {
  //     title: "Application Performance Management",
  //     points: [
  //       "Proactive monitoring for application health",
  //       "Synthetic user monitoring",
  //       "Real user monitoring",
  //       "Transaction tracking & root cause analysis for application performance degradation",
  //       "Stress testing for your applications",
  //     ],
  //   },
  //   {
  //     title: "Software Development Life Cycle Management",
  //     points: [
  //       "Tracking application release from initial version till Go Live",
  //       "Checking security holes and defects within your application",
  //       "Testing application vs different platforms and environments",
  //     ],
  //   },
  //   {
  //     title: "IT Service & Asset Management",
  //     points: [
  //       "Applying ITIL best practice processes",
  //       "Automatic discovery for IT assets & application relations",
  //       "Accurate capacity management for IT assets for either hardware assets or software licenses",
  //       "IT asset life cycle management from new entry till retirement",
  //       "SLA measurement for response and resolution of requests & tickets",
  //     ],
  //   },
  //   {
  //     title: "Capacity management:",
  //     points: [
  //       "With Capacity Optimization, you can model future resource needs and predict what IT resources you will need. The IT infrastructure includes physical, virtual, containers, private cloud, and public clouds.",
  //       "Capacity visibility, Gain visibility into resource capacity, utilization and utilization trends.",
  //       "Risk management, Identify and avoid risks such as immediate resource saturation or upcoming resource saturation.",
  //       "Resource optimization, identify issues that affect the efficiency and resolve them. For example, idle VMs, overallocated VMs.",
  //       "Capacity planning, Predict the infrastructure needs, estimate whether the existing infrastructure is adequate to meet the current and future demands",
  //     ],
  //   },
  // ];

  return (
    <div className="ITManagementSection3">
      <div className="ITManagementSection3-top">
        <div className="row responsive-row">
          <div className="col">
            <div className="row responsive-row">
              <div className="ITManagementSection3-top-card">
                <div className="ITManagementSection3-top-card-title">
                  Network Management
                </div>
                <div className="ITManagementSection3-top-card-points">
                  <ul>
                    <li>
                      Automatic discovery & proactive monitoring for the whole
                      network infrastructure
                    </li>
                    <li>Performance & quality measurement</li>
                    <li>Automation & compliance management</li>
                    <li>Traffic Analysis & Bandwidth optimization</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row responsive-row">
              <div className="ITManagementSection3-top-card">
                <div className="ITManagementSection3-top-card-title">
                  Application Performance Management
                </div>
                <div className="ITManagementSection3-top-card-points">
                  <ul>
                    <li> Proactive monitoring for application health</li>
                    <li> Synthetic user monitoring</li>
                    <li> Real user monitoring</li>
                    <li>
                      Transaction tracking & root cause analysis for application
                      performance degradation
                    </li>
                    <li> Stress testing for your applications</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row responsive-row">
              <div className="ITManagementSection3-top-card">
                <div className="ITManagementSection3-top-card-title">
                  Software Development Life Cycle Management
                </div>
                <div className="ITManagementSection3-top-card-points">
                  <ul>
                    <li>
                      Tracking application release from the initial version till goes
                      live
                    </li>
                    <li>
                      Checking security holes and defects within your
                      application
                    </li>
                    <li>
                      Testing applications vs different platforms and
                      environments
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <div className="ITManagementSection3-top-card">
                <div className="ITManagementSection3-top-card-title">
                  IT Service & Asset Management
                </div>
                <div className="ITManagementSection3-top-card-points">
                  <ul>
                    <li> Applying ITIL best practice processes</li>
                    <li>
                      Automatic discovery for IT assets & application relations
                    </li>
                    <li>
                      Accurate capacity management for IT assets for either
                      hardware assets or software licenses
                    </li>
                    <li>
                      IT asset life cycle management from new entry till
                      retirement
                    </li>
                    <li>
                      SLA measurement for response and resolution of requests &
                      tickets
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ITManagementSection3-top-card">
                <div className="ITManagementSection3-top-card-title">
                  Capacity management:
                </div>
                <div className="ITManagementSection3-top-card-points">
                  <ul>
                    <li>
                      With Capacity Optimization, you can model future resource
                      needs and predict what IT resources you will need. The IT
                      infrastructure includes physical, virtual, containers,
                      private cloud, and public clouds.
                    </li>
                    <li>
                      Capacity visibility, Gain visibility into resource
                      capacity, utilization and utilization trends.
                    </li>
                    <li>
                      Risk management, Identify and avoid risks such as
                      immediate resource saturation or upcoming resource
                      saturation.
                    </li>
                    <li>
                      Resource optimization, identify issues that affect the
                      efficiency and resolve them. For example, idle VMs,
                      overallocated VMs.
                    </li>
                    <li>
                      Capacity planning, Predict the infrastructure needs,
                      estimate whether the existing infrastructure is adequate
                      to meet the current and future demands
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ITManagementSection3-bottom">
        <Link to={"/ContactUs"}>
          <div className="ITManagementSection3-bottom-btn">
            Talk to an expert <ArrowRightCircleIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ITManagementSection3;
