import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home/Home";
import Solutions from "./components/Solutions/All Solutions/Solutions";
import DigitalBusinessAutomation from "./components/Solutions/DigitalBusinessAutomation/DigitalBusinessAutomation";
import DataAndAIServices from "./components/Solutions/DataAndAIServices/DataAndAIServices";
import ITManagement from "./components/Solutions/ITManagement/ITManagement";
import ContactUs from "./components/ContactUs/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import OurTechnologies from "./components/OurTechnologies/OurTechnologies";
import Clients from "./components/Clients/Clients";
import useScrollToTop from "./Hooks/useScrollToTop";

const AppRoutes = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Solutions" element={<Solutions />} />
      <Route
        path="/Solutions/DigitalBusinessAutomation"
        element={<DigitalBusinessAutomation />}
      />
      <Route
        path="/Solutions/DataAndAIServices"
        element={<DataAndAIServices />}
      />
      <Route path="/Solutions/ITManagement" element={<ITManagement />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/Technologies" element={<OurTechnologies />} />
      <Route path="/Clients" element={<Clients />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
