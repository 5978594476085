import ACUD from "../../../../images/photo/home/TrustedCompanies/ACUD.png";
import AlRajhiBank from "../../../../images/photo/home/TrustedCompanies/AlRajhiBank.png";
import CIB from "../../../../images/photo/home/TrustedCompanies/CIB.png";
import EAF from "../../../../images/photo/home/TrustedCompanies/EAF.png";
import EgyptAir from "../../../../images/photo/home/TrustedCompanies/EgyptAir.png";
import KuawaitOil from "../../../../images/photo/home/TrustedCompanies/KuawaitOil.png";
import MTN from "../../../../images/photo/home/TrustedCompanies/MTN.png";
import saib from "../../../../images/photo/home/TrustedCompanies/saib.png";
import STC from "../../../../images/photo/home/TrustedCompanies/STC.png";
import Specialized from "../../../../images/photo/home/TrustedCompanies/Specialized.png";
import TVTC from "../../../../images/photo/home/TrustedCompanies/TVTC.png";
import Vodafone from "../../../../images/photo/home/TrustedCompanies/Vodafone.png";
import YT from "../../../../images/photo/home/TrustedCompanies/Y.T.png";
import EFG from "../../../../images/photo/home/TrustedCompanies/EFG.png";
import EGYPT_POST from "../../../../images/photo/home/TrustedCompanies/EGYPT_POST.svg";
import egyptionSteel from "../../../../images/photo/home/TrustedCompanies/egyptionSteel.png";
import etisalat from "../../../../images/photo/home/TrustedCompanies/etisalat.png";
import idb from "../../../../images/photo/home/TrustedCompanies/Idb.png";
import minstreOfFunds from "../../../../images/photo/home/TrustedCompanies/minstreOfFunds.png";
import NTRA from "../../../../images/photo/home/TrustedCompanies/NTRA.png";
import petrojet from "../../../../images/photo/home/TrustedCompanies/petrojet.png";
import ministryOfTransport from "../../../../images/photo/home/TrustedCompanies/ministry-of-transport.png";
import OmanInsurance from "../../../../images/photo/home/TrustedCompanies/OmanInsurance.png";
import majidAlFuttaim from "../../../../images/photo/home/TrustedCompanies/majidAlFuttaim.png";
import kingAbdulazizUniversity from "../../../../images/photo/home/TrustedCompanies/king-abdulaziz-university.png";
import Adib from "../../../../images/photo/home/TrustedCompanies/Adib.jpg";
import BanqueDuCaire from "../../../../images/photo/home/TrustedCompanies/BanqueDuCaire.png";
import beaah from "../../../../images/photo/home/TrustedCompanies/beaah.jpg";
import BinRashed from "../../../../images/photo/home/TrustedCompanies/BinRashed.jpg";
import FabMisr from "../../../../images/photo/home/TrustedCompanies/FabMisr.jpg";
import Enppi from "../../../../images/photo/home/TrustedCompanies/Enppi.png";
import MisrDigitalInnovation from "../../../../images/photo/home/TrustedCompanies/MisrDigitalInnovation.png";
import MTLS from "../../../../images/photo/home/TrustedCompanies/MTLS.png";
import efinance from "../../../../images/photo/home/TrustedCompanies/efinance.jpg";
import EGBank from "../../../../images/photo/home/TrustedCompanies/EGBank.jpg";
import orange from "../../../../images/photo/home/TrustedCompanies/orange.png";
import RM from "../../../../images/photo/home/TrustedCompanies/RM.png";
import LabourMRA from "../../../../images/photo/home/TrustedCompanies/LabourMRA.jpg";
import mashreq from "../../../../images/photo/home/TrustedCompanies/mashreq.jpg";
import MHESR from "../../../../images/photo/home/TrustedCompanies/MHESR.jpg";
import "./ClientsSection1.css";

const ClientsSection1 = () => {
  const clientsArray = [
    AlRajhiBank,
    ACUD,
    CIB,
    EAF,
    EgyptAir,
    KuawaitOil,
    MTN,
    saib,
    STC,
    Specialized,
    TVTC,
    Vodafone,
    YT,
    EFG,
    EGYPT_POST,
    egyptionSteel,
    etisalat,
    idb,
    minstreOfFunds,
    NTRA,
    ministryOfTransport,
    petrojet,
    OmanInsurance,
    majidAlFuttaim,
    kingAbdulazizUniversity,
    Adib,
    BanqueDuCaire,
    beaah,
    BinRashed,
    FabMisr,
    Enppi,
    MisrDigitalInnovation,
    MTLS,
    efinance,
    EGBank,
    orange,
    RM,
    LabourMRA,
    mashreq,
    MHESR
  ];

  return (
    <div className="ClientsSection1">
      {clientsArray.map((logo, index) => (
        <div className="ClientsSection1-logo fadeInBottom" key={index}>
          <img src={logo} alt={logo} />
        </div>
      ))}
    </div>
  );
};

export default ClientsSection1;
