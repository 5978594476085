import symbol from "../../../../../images/photo/home/Feedback/symbol.svg";

import "./CustomerOpinionCard.css";
const CustomerOpinionCard = (prop) => {
  const { image, companyName, companyFeedback } = prop;
  return (
    <div className="CustomerOpinionCard">
      <div className="CustomerOpinionCard-content">
        <div className="CustomerOpinionCardMed">
          <div className="CustomerOpinionCardIconframeContainer">
            <div className="CustomerOpinionCardIconframe">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
        <div className="CustomerOpinionCardBottom">
          <div className="CustomerOpinionCardCompName">{companyName}</div>
          <div className="CustomerOpinionCardImg">
            <img src={symbol} alt="" />
          </div>
          <div className="CustomerOpinionCardFeedback">{companyFeedback}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOpinionCard;
