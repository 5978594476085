import SolutionsHeader from "../SolutionsHeader/SolutionsHeader";
import DigitalBusinessSection1 from "./Sections/Section1/DigitalBusinessSection1";
import DigitalBusinessSection2 from "./Sections/Section2/DigitalBusinessSection2";
import DigitalBusinessSection3 from "./Sections/Section3/DigitalBusinessSection3";
import DigitalBusinessSection4 from "./Sections/Section4/DigitalBusinessSection4";
import "./DigitalBusinessAutomation.css";

const DigitalBusinessAutomation = () => {
  const path = window.location.pathname;
  return (
    <div className="DigitalBusinessAutomation">
      <SolutionsHeader
        pageName={"Digital Business Automation"}
        path={path}
        endPathName={"Digital Business Automation"}
      />
      <div className="DigitalBusinessAutomationSections">
        <DigitalBusinessSection1 />
        <DigitalBusinessSection2 />
        <DigitalBusinessSection3 />
        <DigitalBusinessSection4 />
      </div>
    </div>
  );
};

export default DigitalBusinessAutomation;
