import styled from "styled-components";
import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";
import "./DigitalBusinessSection2.css";

const CheckCircleIcon = styled(CheckCircle)`
  color: #7f68b4;
  width: 24px;
`;

const DigitalBusinessSection2 = () => {
  return (
    <div className="DigitalBusinessSection2">
      <div className="DigitalBusinessSection2-text-1">
        Automate File Transfers
      </div>
      <div className="DigitalBusinessSection2-text-2">
        Streamline Business Services with Advanced File Transfer Automation
        Minimize risk and accelerate service delivery by automating both
        internal and external file transfers alongside related application
        workflows in hybrid environments. Our solution integrates application
        workflow orchestration with intelligent file movement, offering:
      </div>
      <div className="DigitalBusinessSection2-text-3">
        <div className="DigitalBusinessSection2-text-3-left">
          <div>
            <CheckCircleIcon />
            <p>
              <span>Secure Operations:</span> Schedule and manage your file
              transfers securely. Achieve peace of mind with FIPS compliance and
              policy-driven processing rules ensuring your data is protected.
            </p>
          </div>
          <div>
            <CheckCircleIcon />
            <p>
              <span>Robust Compatibility:</span> EEffortlessly move files with
              extensive support for various storage solutions including S3,
              Azure Blob Storage, Azure Data Lake Storage, Google Cloud Storage,
              and traditional protocols like FTP, SFTP, FTPS, HTTP/S, and AS2*.
            </p>
          </div>
        </div>
        <div className="DigitalBusinessSection2-text-3-right">
          <div>
            <CheckCircleIcon />
            <p>
              <span>Consolidated View:</span> Eliminate the risks associated
              with disparate file transfer methods. Gain a comprehensive
              360-degree view through customizable dashboards and advanced
              analytics.
            </p>
          </div>
          <div>
            <CheckCircleIcon />
            <p>
              <span>Integrated Management: </span> Control file transfers and
              application workflows from a single, unified interface,
              simplifying operations and enhancing efficiency in your hybrid
              environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalBusinessSection2;
