import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CustomerOpinionCard from "./Card/CustomerOpinionCard";
import CustomerOpinionStaticData from "./../CustomerOpinionStaticData";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./CustomerOpinionSwiper.css";

export default function CustomerOpinionSwiper() {
  const feedbacks = CustomerOpinionStaticData();
  return (
    <div className="CustomerOpinionSwiperContainer">
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={32}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="CustomerOpinionSwiper"
      >
        {feedbacks.map((feed, index) => (
          <SwiperSlide
            key={index}
            width={800}
            className="CustomerOpinionSwiperSlide"
          >
            <CustomerOpinionCard
              image={feed.image}
              companyName={feed.companyName}
              companyFeedback={feed.companyFeedback}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
