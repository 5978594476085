import OurTechnologiesHeader from "./Sections/OurTechnologiesHeader/OurTechnologiesHeader";
import OurTechnologiesSection1 from "./Sections/Section1/OurTechnologiesSection1";

import "./OurTechnologies.css";

const OurTechnologies = () => {
  const path = window.location.pathname;
  return (
    <div className="OurTechnologies">
      <OurTechnologiesHeader pageName={"Our Technologies"} path={path} />
      <OurTechnologiesSection1 />
    </div>
  );
};

export default OurTechnologies;
