import number from "./04.svg";
import "./StepsGraph.css";

const StepsGraph = () => {
  const staticData = [
    {
      title: "01.Discussion",
      description:
        "The first step is meeting with our customers at a designated location to thoroughly discuss their needs and demands. This ensures that we fully understand the requirements before proposing any plans.",
    },
    {
      title: "02.Concept & Initiatives",
      description:
        "Our experts come up with all kinds of ideas and initiatives for delivering the best solutions for IT services chosen.",
    },
    {
      title: "03.Testing & Trying",
      description:
        "Following agreement on the ideas and the plan, we proceed with the scheduled testing. This step is dedicated to assessing the outcomes, making necessary adjustments, and fine-tuning our approach based on feedback.",
    },
    {
      title: "04.Execute & Install",
      description:
        "Upon final approval of the plan, we execute and install everything per the agreed contract. This ensures a all-in-one transition and implementation of the new systems.",
    },
  ];

  return (
    <div className="StepsGraph">
      <div className="StepsGraph-top">
        <div className="StepsGraph-top-1"></div>
        <div className="StepsGraph-top-2">
          <img src={number} alt="" className="StepsGraph-top-2-img" />
          <div className="StepsGraph-top-3">STEPS</div>
        </div>
      </div>
      <div className="StepsGraph-bottom">
        <div className="StepsGraph-bottom-numbers">
          <div className="StepsGraph-bottom-numbers-line"></div>
          {staticData.map((el, index) => (
            <div key={index} className="StepsGraph-bottom-numbers-circule">
              {index + 1}
            </div>
          ))}
        </div>
        <div className="StepsGraph-bottom-steps">
          {staticData.map((el, index) => (
            <div className="StepsGraph-bottom-steps-card" key={index}>
              <div className="StepsGraph-bottom-steps-card-title">
                {el.title}
              </div>
              <div className="StepsGraph-bottom-steps-card-description">
                {el.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepsGraph;
