import AutomateApplication from "../images/photo/home/solution section/Digital Business Automation.jpg";
import BigData from "../images/photo/home/solution section/big data.jpg";
import ITManagement from "../images/photo/home/solution section/It managment.png";

export function NavRoutes() {
  return [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "About Us",
      route: "/AboutUs",
    },
    {
      name: "Solutions",
      route: "/Solutions",
      dropdown: [
        {
          image: BigData,
          name: "Data and AI Services",
          route: "/Solutions/DataAndAIServices",
          description:
            "Aimed at helping companies handle massive-scale data for smooth software operation and reliable analytics insights.",
        },
        {
          image: AutomateApplication,
          name: "Digital Business Automation",
          route: "/Solutions/DigitalBusinessAutomation",
          description:
            "Streamline your workflow with our automation technology, eliminating the need for manual processes and boosting productivity like never before.",
        },
        {
          image: ITManagement,
          name: "IT Management",
          route: "/Solutions/ITManagement",
          description:
            "Monitoring and administration of an organization's information technology systems: hardware, software and networks.",
        },
      ],
    },
    {
      name: "Technologies",
      route: "/Technologies",
    },
    {
      name: "Clients",
      route: "/Clients",
    },
    {
      name: "Contact Us",
      route: "/ContactUs",
    },
  ];
}
