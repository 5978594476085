import styled from "styled-components";
import { LocationPin } from "@styled-icons/entypo/LocationPin";
import { Telephone } from "@styled-icons/bootstrap/Telephone";
import { Phone } from "@styled-icons/fluentui-system-filled/Phone";
import { AtSign } from "@styled-icons/foundation/AtSign";
import { Fax } from "@styled-icons/fa-solid/Fax";
import { MarkunreadMailbox } from "@styled-icons/material-twotone/MarkunreadMailbox";
import "./Section2Card.css";

const LocationPinIcon = styled(LocationPin)`
  color: #f7c61d;
  width: inherit;
`;

const PhoneIcon = styled(Phone)``;
const AtSignIcon = styled(AtSign)``;
const FaxIcon = styled(Fax)``;
const MarkunreadMailboxIcon = styled(MarkunreadMailbox)``;

const Section2Card = ({
  title,
  address,
  phone,
  fax,
  mobile,
  postalCode,
  email,
}) => {
  return (
    <div className="Section2Card">
      <div className="Section2Card-title">{title}</div>
      <div className="Section2Card-Address-container">
        <div className="Section2Card-Address-Icon">
          <LocationPinIcon />
        </div>
        <div className="Section2Card-address">{address}</div>
      </div>
      <hr />
      <div
        className={`Section2Card-contactsInfo ${
          title === "Saudi Arabia" ? "Section2Card-SaudArab" : ""
        }`}
      >
        <div>
          {phone ? (
            <div className="Section2Card-contact Section2Card-contact-Telephone">
              <Telephone className="Section2Card-contact-Icon" />
              <div className="Section2Card-contact-Telephones">
                {phone.map((el, index) => (
                  <div key={index} className="Section2Card-contact-Telephone">
                    {el}
                    {index < phone.length - 1 ? "," : ""}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="Section2Card-contact">
            <PhoneIcon className="Section2Card-contact-Icon" />
            {mobile}
          </div>
          <div className="Section2Card-contact">
            <AtSignIcon className="Section2Card-contact-Icon" />
            {email}
          </div>
        </div>
        <div className="Section2Card-contactsInfo-bottom">
          {fax ? (
            <div className="Section2Card-contact">
              <FaxIcon className="Section2Card-contact-Icon" />
              {fax}
            </div>
          ) : (
            ""
          )}
          {postalCode ? (
            <div className="Section2Card-contact">
              <MarkunreadMailboxIcon className="Section2Card-contact-Icon" />
              {postalCode}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Section2Card;
