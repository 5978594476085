import { Link } from "react-router-dom";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import styled from "styled-components";
import "./DigitalBusinessSection4.css";

const ArrowRightCircleIcon = styled(ArrowRightCircle)``;

const DigitalBusinessSection4 = () => {
  return (
    <div className="DigitalBusinessSection4">
      <div className="DigitalBusinessSection4-top">
        <div className="DigitalBusinessSection4-top-title">
          Automation of Manual Processes:
        </div>
        <div className="DigitalBusinessSection4-top-description">
        Streamline Your Workflow with Our Advanced Automation Technology.
        Transform your operations by eliminating manual processes and boosting productivity like never before. 
        Discover the advantages of our sophisticated features:
        </div>
      </div>
      <div className="DigitalBusinessSection4-mid">
        <div className="row responsive-row">
          <div className="row responsive-row">
            <div className="col">
              <div className="DigitalBusinessSection4-mid-card">
                <div className="DigitalBusinessSection4-mid-card-title">
                  Data Connectors
                </div>
                <div className="DigitalBusinessSection4-mid-card-description">
                Enhance your outcomes effortlessly with our expansive array of data connectors. 
                Our automation technology simplifies and accelerates the process of analytics automation,
                delivering optimal results in significantly less time.
                </div>
              </div>
            </div>
            <div className="col">
              <div className="DigitalBusinessSection4-mid-card">
                <div className="DigitalBusinessSection4-mid-card-title">
                  Security and Governance
                </div>
                <div className="DigitalBusinessSection4-mid-card-description">
                Trust in the most rigorous data protection and compliance provided by our Alteryx Analytics Automation Platform. 
                We uphold the highest standards of security, privacy, trust, and sustainability to protect your valuable data.
                </div>
              </div>
            </div>
          </div>
          <div className="row responsive-row">
            <div className="col">
              <div className="DigitalBusinessSection4-mid-card">
                <div className="DigitalBusinessSection4-mid-card-title">
                  Collaboration & Versioning
                </div>
                <div className="DigitalBusinessSection4-mid-card-description">
                Facilitate enhanced collaboration within your team. Our platform allows everyone to contribute their expertise,
                leading to faster onboarding and more effective sharing. Leverage these assets across groups to accelerate your
                analytics journey.
                </div>
              </div>
            </div>
            <div className="col">
              <div className="DigitalBusinessSection4-mid-card">
                <div className="DigitalBusinessSection4-mid-card-title">
                  Metrics Store
                </div>
                <div className="DigitalBusinessSection4-mid-card-description">
                Empower your analysts and data engineers to create, customize, and share a catalog of business metrics,
                accessible by any BI tool or application. Our platform includes templates for common metrics,
                streamlining the analytics process.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="DigitalBusinessSection4-bottom">
        <Link to={"/ContactUs"}>
          <div className="DigitalBusinessSection4-bottom-btn">
            Talk to an expert{" "}
            <ArrowRightCircleIcon className="ArrowRightCircleIcon" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DigitalBusinessSection4;
