import ITManagementSection1 from "./Sections/Section1/ITManagementSection1";
import ITManagementSection2 from "./Sections/Section2/ITManagementSection2";
import ITManagementSection3 from "./Sections/Section3/ITManagementSection3";
import SolutionsHeader from "../SolutionsHeader/SolutionsHeader";

import "./ITManagement.css";

const ITManagement = () => {
  return (
    <div className="ITManagement">
      <SolutionsHeader
        pageName={"IT Management"}
        path={window.location.pathname}
      />
      <ITManagementSection1 />
      <ITManagementSection2 />
      <ITManagementSection3 />
    </div>
  );
};

export default ITManagement;
